<script>
import PropostaDetalhe from '@/components/shared/proposta/PropostaDetalhe.vue'

export default {
  extends: PropostaDetalhe,

  data() {
    return {
      modulo: 'servidor',
    }
  },

  methods: {
    confirmarAprovarProposta() {
      this.exibirModalAprovar = true
      this.exibirModalAprovarSenha = false
    },
  },
}
</script>
